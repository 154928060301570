.worker-card {
  border: 1px solid gray;
  border-radius: 15px;
  max-width: 700px;
  margin: 25px 20px;
  padding: 5px;
  overflow: hidden;
  position: relative;

  .last-month-btn{
    padding: 10px 15px;
    border-radius: 11px;
    background: white;
  }

  h1 {
    text-align: center;
    padding: 20px;
  }
  h2 {
    font-size: 20px;
  }
  .content {
    display: flex;
    gap: 25px;
    justify-content: center;
  }
  .columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    margin: 15px;
    h3 {
      height: 25px;
    }
  }
  .logout {
    position: absolute;
    right: 5px;
    top: 5px;
    border: 0;
    background: rgb(0, 163, 87);
    width: 100px;
    height: 40px;
    border-radius: 15px;
    color: white;
    font-size: 18px;
  }
  .set {
    gap: 7px;
    border: 0;
    background: rgba(0, 255, 145, 0.546);
    border-radius: 7px;
    width: 100px;
    height: 24px;
    box-shadow: 0 0 1px 1px gray;
  }
  .setInput {
    display: flex;
    justify-content: center;
    gap: 6px;
    input {
      border: 0;
      background: white;
      border-radius: 7px;
      width: 70px;
      height: 24px;
      box-shadow: 0 0 1px 1px gray;
      text-align: center;
    }
    span {
      background: rgb(0, 163, 87);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .info {
    padding-top: 25px;
  }
  .set-pass {
    input {
      text-align: center;
      width: 150px;
      height: 35px;
      border-radius: 7px;
    }
    button {
      transition: 0.7s;
      border-radius: 7px;
      padding: 15px 25px;
      border: 0;
    }
  }

  .form-get-money{
    display: flex;
    gap: 10px;
    input{
      height: 40px;
      border-radius: 10px;
      text-align: center;
      font-size: 24px;
      width: 150px;
    }
    button{
      border: 0;
      border-radius: 7px;
      padding: 5px;
      background: orange;
    }
  }
  
}

