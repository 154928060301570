.users-list {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

 

  input {
    width: 60px;
    height: 40px;
    font-size: 14px;
    text-align: center;
  }
  button {
    width: 100px;
    height: 40px;
    background: rgba(255, 0, 0, 0.555);
    border: 0;
    border-radius: 10px;
    transition: 0.7s;
    font-size: 16px;
  }
 
}

.user-adding {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .name{
    padding: 10px 15px;
    border-radius: 7px;
  }
  button {
    width: 100px;
    height: 40px;
    background: rgba(255, 0, 0, 0.555);
    border: 0;
    border-radius: 10px;
    transition: 0.7s;
    font-size: 16px;
  }
}

.inputs-days{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  input{
    background: black;
    color: white;
    border-radius: 5px;
    width: 70px;
    height: 40px;
    text-align: center;
    &::placeholder{
      color: white;
    }
    &:focus{
      outline: none;
    }
  }
  button{
    background: green;
    color: white;
  }
}