.dutys-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .button-send-all{
    padding: 10px 25px;
    button{
      padding: 10px 25px;
      font-size: 20px;
      border-radius: 9px;
      margin-bottom: 100px;
      background: rgb(0, 179, 0);
    }
  }
}

.dutys-users-row {
  display: flex;
  flex-direction: column;
  gap: 7px;
  input {
    width: 90px;
    height: 35px;
    text-align: center;
    padding: 3px;
    border-radius: 5px;
    margin: 0 15px;
    font-size: 19px;
  }
}
