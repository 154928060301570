* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

button {
  cursor: pointer;
}


table {
  margin: 0 15px;
  border: 3px solid black;
  border-radius: 20px;
  overflow: hidden;
  padding: 7px;

  td {
    border-radius: 5px;
    padding: 3px;
  }
  th {
    border-radius: 13px;
    padding: 5px 15px;
  }
}

td {
  min-width: 40px;
  height: 40px;
  text-align: center;
}

.next-month{
  padding: 15px;
  margin: 10px ;
  font-size: 19px;
}

body {
  background: linear-gradient(
    rgba(0, 204, 255, 0.438),
    rgba(192, 107, 107, 0.63)
  );
  min-height: 100vh;
}

::-webkit-scrollbar {
  display: none;
}
