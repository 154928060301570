.dashboard-ul {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  a {
    text-decoration: none;
    color: black;
    font-size: 28px;
    padding: 5px 20px;
    background: linear-gradient(white, rgba(128, 128, 128, 0.635));
    border-radius: 7px;
  }
}
.log-out {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20vw;
  height: 30px;
  background: rgba(255, 83, 83, 0.806);
  border: none;
  border-radius: 10px;
  font-size: 19px;
}
