.login-worker {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5vh;

  input {
    width: 50vw;
    height: 35px;
    border: 0;
    border-radius: 10px;
    padding-left: 15px;
    font-size: 20px;
    transition: 0.6s;
    &:focus {
      outline: none;
      background: rgb(255, 244, 119);
    }
  }

  button {
    border: 0;
    font-size: 21px;
    border-radius: 10px;
    padding: 7px 25px;
  }

  .sign-in {
    background: linear-gradient(rgb(68, 255, 47), rgba(38, 255, 0, 0.26));
  }

  .admin {
    background: linear-gradient(
      rgba(224, 71, 71, 0.486),
      rgba(0, 68, 255, 0.646)
    );
  }
}
